import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import SelectTables from "../components/SelectTables";
import SelectRecipients from "../components/SelectRecipients";
import "./Homescreen.css";

const Homescreen = () => {
  const [tables, setTables] = useState([]);
  const [recipients, setRecipients] = useState([]);

  useEffect(() => {
    // Fetch initial values for tables and recipients from the API
    fetch(
      "https://odma0zsuk5.execute-api.ap-south-1.amazonaws.com/export_settings"
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.tables && Array.isArray(data.tables)) {
          setTables(data.tables);
        }
        if (data && data.recipients && Array.isArray(data.recipients)) {
          setRecipients(data.recipients);
        }
      })
      .catch((error) => {
        console.error("Error fetching initial values:", error);
      });
  }, []);

  const handleSaveExportSettings = () => {
    const requestBody = {
      tables: tables,
      recipients: recipients,
    };

    fetch(
      "https://odma0zsuk5.execute-api.ap-south-1.amazonaws.com/export_settings",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle the response data if needed
        console.log("API response:", data);
        alert("Export Settings Saved successfully!");
      })
      .catch((error) => {
        // Handle errors if any
        console.error("Error submitting data:", error);
      });
  };

  const handleExport = () => {
    fetch(
      "https://odma0zsuk5.execute-api.ap-south-1.amazonaws.com/export_job",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle the response data if needed
        console.log("API response:", data);
        alert(
          "The export process has been submitted successfully! Grab a cup of coffee while we get your tables ready. We will export them to the provided recipients shortly."
        );
      })
      .catch((error) => {
        // Handle errors if any
        console.error("Error submitting data:", error);
      });
  };

  return (
    <div className="container">
      <Header />
      <SelectTables tables={tables} setTables={setTables} />
      <SelectRecipients recipients={recipients} setRecipients={setRecipients} />

      <div className="submit_container">
        <button
          onClick={handleSaveExportSettings}
          className="submit_button_save_export_settings"
        >
          Save Export Settings
        </button>

        <button onClick={handleExport} className="submit_button_export">
          Export
        </button>
      </div>
    </div>
  );
};

export default Homescreen;
