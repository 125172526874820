import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homescreen from "./screens/Homescreen";
import Login from "./screens/Login";

const App = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/homescreen" element={<Homescreen />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Login />} />{" "}
          {/* Fallback route for unmatched paths */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
