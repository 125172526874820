import React, { useState } from "react";
import "./SelectRecipients.css";

const SelectRecipients = ({ recipients, setRecipients }) => {
  const [inputValue, setInputValue] = useState("");

  // Function to handle adding a recipient
  const handleAddRecipient = () => {
    if (inputValue.trim() !== "") {
      setRecipients((prevRecipients) => [...prevRecipients, inputValue]);
      setInputValue("");
    }
  };

  // Function to handle removing a recipient
  const handleRemoveRecipient = (index) => {
    setRecipients((prevRecipients) =>
      prevRecipients.filter((_, i) => i !== index)
    );
  };

  return (
    <div className="select-recipients-container">
      <label className="select-recipients-text">Select recipients</label>

      <div className="select-recipients-input-container">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className="select-recipients-input"
        />
        <button
          onClick={handleAddRecipient}
          className="select-recipients-input-button"
        >
          Add
        </button>
      </div>

      <div className="recipients-list">
        {recipients.map((recipient, index) => (
          <div key={index} className="recipient-item">
            <div className="recipient-name">{recipient}</div>
            <div
              className="remove-icon"
              onClick={() => handleRemoveRecipient(index)}
            >
              <img
                src="https://img.icons8.com/ios-glyphs/90/multiply.png"
                alt="Remove"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectRecipients;
