import React, { Component } from "react";
import { Link } from "react-router-dom";
import clientellLogo from "../assets/clientell-logo.png";
import eyLogo from "../assets/ey-logo.png";
import "./Header.css";

const Header = () => {
  const handleLogout = () => {
    // Implement your logout logic here, e.g., clearing session data or tokens
    // After logout, redirect to the login screen
    // Assuming the login screen URL is "/login"
  };

  return (
    <div className="header-image-container">
      <img src={clientellLogo} alt="Clientell Logo" />
      <div className="header-image-divider"></div>
      <img src={eyLogo} alt="EY Logo" />
      <div className="header-logout" onClick={handleLogout}>
        <Link to="/login" className="header-logout-link">
          Logout
        </Link>
      </div>
    </div>
  );
};

export default Header;
