import React, { useState, useEffect } from "react";
import "./SelectTables.css";

const SelectTables = ({ tables, setTables }) => {
  const [checkboxData, setCheckboxData] = useState([]);

  useEffect(() => {
    // Fetch checkboxData from the API
    fetch("https://odma0zsuk5.execute-api.ap-south-1.amazonaws.com/get_tables")
      .then((response) => response.json())
      .then((data) => {
        if (data && data.tables && Array.isArray(data.tables)) {
          // Transform the API response into checkboxData format
          const transformedData = data.tables.map((table) => ({
            label: table,
          }));
          setCheckboxData(transformedData);
        }
      })
      .catch((error) => {
        console.error("Error fetching checkbox data:", error);
      });
  }, []);

  // Function to handle checkbox changes
  const handleCheckboxChange = (itemLabel) => {
    setTables((prevTables) =>
      prevTables.includes(itemLabel)
        ? prevTables.filter((label) => label !== itemLabel)
        : [...prevTables, itemLabel]
    );
  };

  return (
    <div className="select-tables-container">
      <label className="select-tables-text">Select Tables</label>

      <div className="checkbox-container">
        {checkboxData.map((item, index) => (
          <div key={index} className="checkbox-item">
            <input
              type="checkbox"
              checked={tables.includes(item.label)}
              onChange={() => handleCheckboxChange(item.label)}
            />
            <label>{item.label}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectTables;
