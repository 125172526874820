import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import clientellLogo from "../assets/clientell-logo.png";
import eyLogo from "../assets/ey-logo.png";
import "./Login.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    // Basic validation, you can add more sophisticated validation here
    if (username === "exampleuser" && password === "examplepassword") {
      navigate("/homescreen");
    } else {
      alert("Invalid username or password. Please try again.");
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-inner-container">
        <div className="image-container">
          <img src={clientellLogo} alt="Clientell Logo" />
          <div className="image-divider"></div>
          <img src={eyLogo} alt="EY Logo" />
        </div>
        <div className="form-container">
          <label className="form-label">Email</label>
          <input
            type="text"
            value={username}
            size="50"
            onChange={(e) => setUsername(e.target.value)}
            className="form-input"
          />
          <label className="form-label">Password</label>
          <input
            type="password"
            value={password}
            size="50"
            onChange={(e) => setPassword(e.target.value)}
            className="form-input"
          />
          <button type="submit" className="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
